
.card-head + div > div { position: relative;}

body > .osd-toolbar-ui-container,
.osd-toolbar-ui { display: flex; align-items: center; height:40px; background:#172832; position: absolute !important; width:100%; bottom:-40px; transition:bottom 300ms ease-in-out; 
  justify-content: space-between; padding:0 8px;
}
.osd-preview { overflow: hidden;}
body > .osd-toolbar-ui-container,
.osd-preview:hover .osd-toolbar-ui { bottom:0; z-index: 1; }

.osd-toolbar-ui .preview { display: inline-flex !important; flex-shrink:0; color:white; flex-wrap: nowrap; font-size:12px; font-weight:500; order:1; cursor:pointer;}
.osd-toolbar-ui .preview img { margin-left:5px; }
.osd-toolbar-ui-container { display: inline-flex; align-items: center;height:100%; order:0; }
.osd-toolbar-ui-container > div {  display: inline-flex; align-items: center;height:100%;}
.osd-toolbar-ui-container > div > div { display: inline-flex; align-items: center; height:100%; position:relative !important;}
.osd-toolbar-ui-container > div > div > div > div { display: inline-flex !important; align-items: center; height:100%;}
.osd-toolbar-ui-container > div > div > div > div > div:nth-child(3) img,
.osd-toolbar-ui-container > div > div > div > div > div:first-child img { height:18px; }
.osd-toolbar-ui-container > div > div > div > div > div img:not(:first-child) { display:none !important; }
.osd-toolbar-ui-container > div > div > div > div > div { padding: 0 8px !important; border-right:1px solid rgba(255,255,255,0.65) !important; height:24px; 
  display: inline-flex !important; align-items: center; cursor: pointer;
}
.osd-toolbar-ui-container > div > div > div > div > div:last-child { border-right:none !important; }